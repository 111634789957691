@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
[data-reactroot] {
  height: 100%;
}

/* inter var - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url('/static/fonts/inter-var-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Drukwide';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/DrukWide-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/SF-Pro-Display-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 300;
  src: url('/static/fonts/SF-Pro-Display-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/SF-Pro-Display-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 400;
  src: url('/static/fonts/SF-Pro-Display-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 500;
  src: url('/static/fonts/SF-Pro-Display-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 500;
  src: url('/static/fonts/SF-Pro-Display-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/SF-Pro-Display-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 600;
  src: url('/static/fonts/SF-Pro-Display-SemiboldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/SF-Pro-Display-Semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 600;
  src: url('/static/fonts/SF-Pro-Display-SemiboldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/SF-Pro-Display-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 700;
  src: url('/static/fonts/SF-Pro-Display-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: normal;
  font-weight: 900;
  src: url('/static/fonts/SF-Pro-Display-Heavy.woff2') format('woff2');
}

@font-face {
  font-family: 'SfPro';
  font-style: italic;
  font-weight: 900;
  src: url('/static/fonts/SF-Pro-Display-HeavyItalic.woff2') format('woff2');
}

/* ReactJS Popover Styles */
[role='dialog'].popup-content {
  @apply m-auto;
  position: unset !important;
}

.popup-arrow {
  @apply text-gray-800;
}
[role='tooltip'].popup-content {
  @apply max-w-[350px] rounded-md bg-gray-800 px-4 py-2 text-sm text-gray-100 drop-shadow-xl;
}

.popup-overlay {
  @apply bg-black/30;
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.15s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.color-picker-container {
  .rcp-saturation {
    @apply rounded-lg;
  }
}

@layer components {
}

@layer base {
  .title-text-shadow::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    @apply text-shadow-soft;
  }

  video {
    max-width: unset;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }

  .btn-disabled,
  .btn[disabled] {
    color: white !important;
    background-color: #c4c4c4 !important;
  }

  .tabs .tab {
    color: grey !important;
  }

  .tabs .tab.tab-active {
    color: var(--bc) !important;
  }

  .input:focus {
    outline: none !important;
  }

  input.checkbox {
    border-radius: 4px;
  }

  .input-placeholder {
    @apply font-primary text-[15px] text-placeholder-text;
  }

  input:not(.checkbox)::placeholder {
    @apply input-placeholder;
  }
}
